import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";

const TechnicianViewDetails = ({
  closeDetailsPopup,
  overallStatus,
  selectedCustomer,
}) => {

    console.log("detais: ",selectedCustomer);
  const statuses = ["Pending", "Approved", "Accepted", "Paid", "Completed"];
  const currentStatus = overallStatus;
  const [selectedStatus, setSelectedStatus] = useState(overallStatus);

  const viewDetailsSpacing = (label, value) => (
    <div className="flex flex-col w-1/2 gap-1">
      <div className="text-sm text-gray-500">{label}</div>
      <div className="text-sm">{value}</div>
    </div>
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center mt-5 bg-gray-500 bg-opacity-75">
          <div className="p-6 bg-white rounded-lg w-[800px] h-[580px] flex flex-col overflow-auto">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closeDetailsPopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col w-full h-full px-2">
              <div className="flex flex-col items-center w-full h-full mx-4 lg:flex-row">
                {/* Left Column */}
                <div className="flex flex-col w-1/2 h-full gap-6">
                  {/* Customer Details */}
                  <div className="flex flex-col w-full">
                    <div className="text-lg mb-4 font-semibold text-[#002252]">
                      Customer Details
                    </div>
                    {/* Personal Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Personal Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "First Name",
                          selectedCustomer.firstName
                        )}
                        {viewDetailsSpacing(
                          "Last Name",
                          selectedCustomer.lastName
                        )}
                      </div>
                      <div className="flex flex-col w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Email Address",
                          selectedCustomer.emailAddress
                        )}
                        {viewDetailsSpacing(
                          "Phone",
                          selectedCustomer.contactNumber
                        )}
                      </div>
                    </div>

                    {/* Address Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Address Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Address",
                          selectedCustomer.address
                        )}
                        {viewDetailsSpacing("City", selectedCustomer.city)}
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing("State", selectedCustomer.state)}
                        {viewDetailsSpacing(
                          "Postal Code",
                          selectedCustomer.postalCode
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="flex flex-col w-1/2 h-full gap-3">
                  <div className="text-lg font-semibold text-[#002252]">
                    Inquiry Status
                  </div>
                  <div className="w-full p-2">
                    <div className="relative">
                      {statuses.map((status, index) => (
                        <React.Fragment key={index}>
                          <div className="relative flex items-center mb-3">
                            <div
                              className={`flex items-center justify-center h-4 w-4 rounded-full mr-3 ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "bg-green-500"
                                  : "bg-gray-500"
                              }`}
                            >
                              {index <= statuses.indexOf(currentStatus) && (
                                <FaCheck className="w-2 h-2 text-white" />
                              )}
                            </div>
                            <div
                              className={`text-base cursor-pointer ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "text-green-500"
                                  : "text-gray-500"
                              }`}
                              onClick={() => setSelectedStatus(status)}
                            >
                              {status}
                            </div>
                            {index < statuses.length - 1 && (
                              <div
                                className={`absolute left-1.5 top-5 h-6 w-0.5 ${
                                  index < statuses.indexOf(currentStatus)
                                    ? "bg-green-500"
                                    : "bg-gray-500"
                                }`}
                              ></div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="mt-6">
                      <div className="flex flex-row w-full gap-4 mb-4">
                        <div className="text-sm">
                          <strong>Type:</strong> {selectedCustomer.type}
                        </div>
                        <div className="text-sm">
                          <strong>Date:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceDate
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskDate
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeDate
                            : selectedStatus === "Paid"
                            ? selectedCustomer.paidDate
                            : selectedStatus === "Completed"
                            ? selectedCustomer.completedDate
                            : "Not Applicable"}
                        </div>
                        <div className="text-sm">
                          <strong>Time:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceTime
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskTime
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeTime
                            : selectedStatus === "Paid"
                            ? selectedCustomer.paidTime
                            : selectedStatus === "Completed"
                            ? selectedCustomer.completedTime
                            : "Not Applicable"}
                        </div>
                      </div>
                      <div className="text-sm">
                        <strong>Comments:</strong>{" "}
                        {selectedStatus === "Pending"
                          ? selectedCustomer.serviceDescription
                          : selectedStatus === "Approved"
                          ? selectedCustomer.taskDescription
                          : selectedStatus === "Accepted"
                          ? selectedCustomer.acknowledgeDescription
                          : selectedStatus === "Paid"
                          ? selectedCustomer.paidDescription
                          : selectedStatus === "Completed"
                          ? selectedCustomer.completedDescription
                          : "Not Applicable"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default TechnicianViewDetails;
