import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";

const TechnicianProfile = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    username: "",
    typeOfExpertise: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [technician, setTechnician] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTechnician, setEditedTechnician] = useState({});
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const [errors, setErrors] = useState({
    licenseNumber: "",
    contractorNumber: "",
  });

  const validatePassword = (password) => {
    const passwordPolicy = {
      minLength: 8,
      hasUpperCase: /[A-Z]/,
      hasLowerCase: /[a-z]/,
      hasNumber: /\d/,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
    };

    let errorMessage = "";
    if (password.length < passwordPolicy.minLength) {
      errorMessage += `Password must be at least ${passwordPolicy.minLength} characters long. `;
    }
    if (!passwordPolicy.hasUpperCase.test(password)) {
      errorMessage += "Password must contain at least one uppercase letter. ";
    }
    if (!passwordPolicy.hasLowerCase.test(password)) {
      errorMessage += "Password must contain at least one lowercase letter. ";
    }
    if (!passwordPolicy.hasNumber.test(password)) {
      errorMessage += "Password must contain at least one digit. ";
    }
    if (!passwordPolicy.hasSpecialChar.test(password)) {
      errorMessage += "Password must contain at least one special character. ";
    }

    return errorMessage;
  };

  useEffect(() => {
    // Fetch technician when component mounts
    fetchTechnician();
  }, []);

  useEffect(() => {
    setEditedTechnician(technician);
  }, [technician]);

  // extracting user is from local storage checking if exist
  if (localStorage.getItem("userid")) {
    var userId = localStorage.getItem("userid");
    console.log("UerID:", userId);
  } else {
    console.log("Value does not exist in local storage.");
  }

  const fetchTechnician = async () => {
    try {
      console.log("Requesting technician data ...");
      const response = await request(
        axiosInstance1,
        "GET",
        `/getTechnicianDetailsByUserId/${userId}`
      );
      setTechnician(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching technicians:", error);
    }
  };

  // capitalize first letter
  const capitalizeFirstLetter = (str) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    setIsEditing(true);
    setEditedTechnician(technician);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedTechnician({});
  };

  const handleFieldChange = (fieldName, value) => {
    let updatedValue = value;
    let errorMessage = "";
  
    // Validation for licenseNumber
    if (fieldName === "licenseNumber") {
      const licensePattern = /^A\d{5}$/; // License must start with 'A' and followed by 5 digits
      if (value && !licensePattern.test(value)) {
        errorMessage = "License number must start with 'A' followed by 5 digits.";
      }
    }
  
    // Validation for contractorNumber
    else if (fieldName === "contractorNumber") {
      const contractorPattern = /^\d{5}$/; // Contractor number must be exactly 5 digits
      if (value && !contractorPattern.test(value)) {
        errorMessage = "Contractor number must be 5 digits.";
      }
    }
    // Capitalize other fields if necessary
    else if (
      fieldName !== "emailAddress" &&
      fieldName !== "contactNumber" &&
      fieldName !== "username" &&
      fieldName !== "typeOfExpertise"
    ) {
      updatedValue = capitalizeFirstLetter(value);
    }
  
    // Update the errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  
    // Update the technician state
    setEditedTechnician((prevTechnician) => ({
      ...prevTechnician,
      [fieldName]: updatedValue,
    }));
  };
  

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  const handleSaveClick = async (event) => {
    event.preventDefault();

    console.log("Edited Technician Data:", editedTechnician);

    setIsEditing(false);
    setTechnician(editedTechnician);

    // Basic form validation
    if (
      !editedTechnician.firstName ||
      !editedTechnician.lastName ||
      !editedTechnician.emailAddress ||
      !editedTechnician.username ||
      !editedTechnician.typeOfExpertise ||
      !editedTechnician.contactNumber ||
      !editedTechnician.address ||
      !editedTechnician.city ||
      !editedTechnician.state ||
      !editedTechnician.postalCode ||
      !editedTechnician.licenseNumber ||
      !editedTechnician.licenseExpireDate ||
      !editedTechnician.contractorNumber ||
      !editedTechnician.contractorExpireDate
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance1,
        "PUT",
        "/updateTechnician/" + userId,
        editedTechnician
      );
      console.log(response);
      // setTechnician(editedTechnician);

      setSuccessMessage("Registration successful.");
      setErrorMessage("");
      console.log(successMessage);

      // Clear form after successful submission
      setEditedTechnician({});
    } catch (error) {
      // setAuthHeader(null);
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    }
  };

  const handleOpenPopup = () => {
    console.log("is opening.....");
    setOpenPopup(true);
    setOverlay(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setOverlay(false);
    setPassword("");
    setConfirmPassword("");
    setConfirmPasswordError("");
    setPasswordError("");
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(validatePassword(newPassword));
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setConfirmPasswordError(
      newConfirmPassword !== password ? "Passwords do not match" : ""
    );
  };

  const handleResetPassword = async () => {
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    setPasswordError("");

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }
    setConfirmPasswordError("");

    try {
      console.log("Reset password start ...");
      const response = await request(
        axiosInstance1,
        "POST",
        `/resetUserPassword/${userId}`,
        password
      );
      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Password Reset Successful",
        text: "You have been successfully reset the password. Login again using new password.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.error("Error fetching technicians:", error);
      Swal.fire({
        icon: "error",
        title: "Password Reset Unsuccessful",
        text: "You have not been successfully reset the password. Try Again.",
        confirmButtonText: "OK",
      });
    }
    setOpenPopup(false);
  };

  return (
    <>
      <div className="lg:px-[100px] px-[20px]">
        <div className="flex flex-col gap-1 pt-5 lg:flex-row">
          <div className="sm:flex hidden flex-col w-full lg:w-4/12 lg:h-[840px] h-full bg-white pt-8 items-center">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-3xl text-[#002252] font-semibold mb-4">
                {technician.firstName + " " + technician.lastName}
              </div>
              <Grid container textAlign={"center"}>
                <Grid item xs={12}>
                  <div className="text-lg">
                    {technician.city + " / " + technician.state}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg">{technician.contactNumber}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg">{technician.postalCode}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="text-lg">
                    {"Licenese No: " + technician.licenseNumber}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="sm:flex hidden flex-row w-full lg:w-8/12 h-full lg:h-[840px] bg-white pt-8 px-8">
            <div className="flex flex-col w-full h-full">
              <div className="text-3xl font-semibold text-[#002252]">
                Technician Profile
              </div>

              <form onSubmit={handleSaveClick}>
                <div className="flex flex-col pt-8">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col items-center w-full lg:w-1/2 lg:flex-row">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          First Name:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(
                                  editedTechnician.firstName
                                ) || ""
                              : technician
                              ? capitalizeFirstLetter(technician.firstName)
                              : "Null"
                          }
                          size="small"
                          sx={{
                            fontSize: "2vh",
                            marginLeft: 1,
                            textTransform: "capitalize",
                            color: "black",
                          }}
                          onChange={(event) =>
                            handleFieldChange("firstName", event.target.value)
                          }
                        />
                      </div>

                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          Last Name:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(
                                  editedTechnician.lastName
                                ) || ""
                              : technician
                              ? capitalizeFirstLetter(technician.lastName)
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("lastName", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col w-full h-full lg:items-center lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          Email:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? editedTechnician.emailAddress || ""
                              : technician
                              ? technician.emailAddress
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange(
                              "emailAddress",
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          User Name:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? editedTechnician.username || ""
                              : technician
                              ? technician.username
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("username", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          Expertise:
                        </div>

                        <div className="w-full lg:pl-1 lg:w-4/6">
                          <TextField
                            className="w-full "
                            labelId="typeOfExpertise"
                            id="typeOfExpertise"
                            value={technician.typeOfExpertise}
                            name="typeOfExpertise"
                            size="small"
                            disabled
                          ></TextField>
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          Address:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(
                                  editedTechnician.address
                                ) || ""
                              : technician
                              ? capitalizeFirstLetter(technician.address)
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("address", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    {technician.typeOfExpertise === "Electrical" && (
                      <>
                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              License Number
                            </div>

                            <div className="w-full lg:pl-1 lg:w-4/6">
                              <TextField
                                className="w-full"
                                disabled={!isEditing}
                           value={
                            isEditing
                              ? editedTechnician.licenseNumber || ""
                              : technician
                              ? technician.licenseNumber
                              : "Null"
                          }
                                size="small"
                                sx={{ fontSize: "2vh", marginLeft: 1 }}
                                error={!!errors.licenseNumber}
                                helperText={errors.licenseNumber}
                                onChange={(event) =>
                                  handleFieldChange(
                                    "licenseNumber",
                                    event.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              License Expire Date
                            </div>

                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              type="date"
                              value={editedTechnician.licenseExpireDate || ""}
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              InputProps={{
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0],
                                },
                              }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "licenseExpireDate",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Contractor Number
                            </div>

                            <div className="w-full lg:pl-1 lg:w-4/6">
                              <TextField
                                className="w-full"
                                disabled={!isEditing}
                                value={editedTechnician.contractorNumber || ""
                                }
                                size="small"
                                sx={{ fontSize: "2vh", marginLeft: 1 }}
                                error={!!errors.contractorNumber}
                                helperText={errors.contractorNumber}
                                onChange={(event) =>
                                  handleFieldChange(
                                    "contractorNumber",
                                    event.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Contractor Expire Date
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              type="date"
                              value={
                                editedTechnician.contractorExpireDate || ""
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              InputProps={{
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0],
                                },
                              }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "contractorExpireDate",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col w-full h-full lg:items-center lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          Contact:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? editedTechnician.contactNumber || ""
                              : technician
                              ? technician.contactNumber
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange(
                              "contactNumber",
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                          City:
                        </div>
                        <TextField
                          className="w-full lg:w-4/6"
                          disabled={!isEditing}
                          value={
                            isEditing
                              ? capitalizeFirstLetter(editedTechnician.city) ||
                                ""
                              : technician
                              ? capitalizeFirstLetter(technician.city)
                              : "Null"
                          }
                          size="small"
                          sx={{ fontSize: "2vh", marginLeft: 1 }}
                          onChange={(event) =>
                            handleFieldChange("city", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-col w-full gap-5 lg:flex-row">
                      <div className="flex flex-col items-center w-full lg:flex-row lg:w-full">
                        <div className="w-full text-lg font-semibold text-gray-600 lg:w-full">
                          <div className="flex flex-col w-full gap-5 lg:flex-col">
                            <div className="flex flex-col w-full gap-5 lg:flex-row">
                              <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                                <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                                  State:
                                </div>
                                <TextField
                                  className="w-full lg:w-4/6 "
                                  disabled={!isEditing}
                                  value={
                                    isEditing
                                      ? capitalizeFirstLetter(
                                          editedTechnician.state
                                        ) || ""
                                      : technician
                                      ? capitalizeFirstLetter(technician.state)
                                      : "Null"
                                  }
                                  size="small"
                                  sx={{ fontSize: "2vh", marginLeft: 1 }}
                                  onChange={(event) =>
                                    handleFieldChange(
                                      "state",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                                <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                                  Postal Code:
                                </div>
                                <TextField
                                  className="w-full lg:w-4/6 "
                                  disabled={!isEditing}
                                  value={
                                    isEditing
                                      ? capitalizeFirstLetter(
                                          editedTechnician.postalCode
                                        ) || ""
                                      : technician
                                      ? capitalizeFirstLetter(
                                          technician.postalCode
                                        )
                                      : "Null"
                                  }
                                  size="small"
                                  sx={{ fontSize: "2vh", marginLeft: 1 }}
                                  onChange={(event) =>
                                    handleFieldChange(
                                      "postalCode",
                                      event.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <Grid
                            sm={12}
                            display={"flex"}
                            justifyContent={"right"}
                          >
                            <Button
                              onClick={handleOpenPopup}
                              variant="contained"
                              sx={{
                                mt: 5,
                                mb: 2,
                                mr: 5,
                                borderRadius: 5,
                                fontSize: "1.6vh",
                                backgroundColor: "#002252",
                                boxShadow: "none",
                                width: "12em",
                                "&:hover": {
                                  backgroundColor: "#002252",
                                  boxShadow: "none",
                                },
                              }}
                            >
                              Reset Password
                            </Button>
                            {isEditing ? (
                              <>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  sx={{
                                    mt: 5,
                                    mb: 2,
                                    mr: 2,
                                    borderRadius: 5,
                                    fontSize: "2vh",
                                    background: "#002252",
                                    boxShadow: "none",
                                    width: "8em",
                                    "&:hover": {
                                      backgroundColor: "#002252",
                                      boxShadow: "none",
                                    },
                                  }}
                                  disabled={
                                    !!errors.licenseNumber ||
                                    !!errors.contractorNumber
                                  }
                                  onClick={handleSaveClick}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    mt: 5,
                                    mb: 2,
                                    borderRadius: 5,
                                    backgroundColor: "transparent",
                                    color: "black",
                                    width: "8em",
                                    fontSize: "2.1vh",
                                    border: "2px solid black",
                                    boxShadow: "none",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      boxShadow: "none",
                                    },
                                  }}
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <Button
                                variant="contained"
                                sx={{
                                  mt: 5,
                                  mb: 2,
                                  borderRadius: 5,
                                  fontSize: "1.8vh",
                                  background: "#002252",
                                  boxShadow: "none",
                                  width: "8em",
                                  "&:hover": {
                                    backgroundColor: "#002252",
                                    boxShadow: "none",
                                  },
                                }}
                                onClick={handleEditClick}
                              >
                                Edit
                              </Button>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              {/* Mobile View */}
              <div className="flex sm:hidden flex-col w-full overflow-x-auto lg:w-4/12 lg:h-[540px] h-full bg-white pt-8 items-center">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-3xl text-[#002252] font-semibold mb-4">
                    {technician.firstName + " " + technician.lastName}
                  </div>
                  <Grid container textAlign={"center"}>
                    <Grid item xs={12}>
                      <div className="text-lg">
                        {technician.city + " / " + technician.state}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="text-lg">{technician.contactNumber}</div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="text-lg">{technician.postalCode}</div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="text-lg">
                        {"Licenese No: " + technician.licenseNumber}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="flex sm:hidden flex-row w-full lg:w-8/12 overflow-x-auto lg:h-[540px] h-full bg-white pt-8 px-8">
                <div className="flex flex-col w-full h-full">
                  <div className="text-3xl font-semibold text-[#002252]">
                    Technician Profile
                  </div>

                  <form onSubmit={handleSaveClick}>
                    <div className="flex flex-col pt-8">
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:w-1/2 lg:flex-row">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              First Name:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.firstName
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.firstName)
                                  : "Null"
                              }
                              size="small"
                              sx={{
                                fontSize: "2vh",
                                marginLeft: 1,
                                textTransform: "capitalize",
                                color: "black",
                              }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "firstName",
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Last Name:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.lastName
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.lastName)
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "lastName",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col w-full h-full lg:items-center lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Email:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? editedTechnician.emailAddress || ""
                                  : technician
                                  ? technician.emailAddress
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "emailAddress",
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              User Name:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? editedTechnician.username || ""
                                  : technician
                                  ? technician.username
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "username",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Expertise:
                            </div>

                            <Select
                              className="w-full lg:w-4/6 "
                              labelId="typeOfExpertise"
                              id="typeOfExpertise"
                              value={
                                isEditing
                                  ? editedTechnician.typeOfExpertise || ""
                                  : technician
                                  ? technician.typeOfExpertise || ""
                                  : ""
                              }
                              onChange={(event) =>
                                handleFieldChange(
                                  "typeOfExpertise",
                                  event.target.value
                                )
                              }
                              name="typeOfExpertise"
                              size="small"
                              disabled={!isEditing}
                            >
                              {/* <MenuItem value="Select">
                      <em>--Select--</em>
                    </MenuItem> */}
                              <MenuItem value="Gas">Gas</MenuItem>
                              <MenuItem value="Electrical">Electrical</MenuItem>
                              <MenuItem value="Smoke">Smoke</MenuItem>
                            </Select>
                          </div>

                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Address:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6"
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.address
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.address)
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange("address", event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Contact:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6 "
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? editedTechnician.contactNumber || ""
                                  : technician
                                  ? technician.contactNumber
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "contactNumber",
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              City:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6 "
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.city
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.city)
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange("city", event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="flex flex-col w-full gap-5 lg:flex-row">
                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              State:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6 "
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.state
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.state)
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange("state", event.target.value)
                              }
                            />
                          </div>

                          <div className="flex flex-col items-center w-full lg:flex-row lg:w-1/2">
                            <div className="w-full text-lg font-semibold text-gray-600 lg:w-2/6">
                              Postal Code:
                            </div>
                            <TextField
                              className="w-full lg:w-4/6 "
                              disabled={!isEditing}
                              value={
                                isEditing
                                  ? capitalizeFirstLetter(
                                      editedTechnician.postalCode
                                    ) || ""
                                  : technician
                                  ? capitalizeFirstLetter(technician.postalCode)
                                  : "Null"
                              }
                              size="small"
                              sx={{ fontSize: "2vh", marginLeft: 1 }}
                              onChange={(event) =>
                                handleFieldChange(
                                  "postalCode",
                                  event.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <Grid sm={12} display={"flex"} justifyContent={"right"}>
                        {isEditing ? (
                          <>
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                mt: 5,
                                mb: 2,
                                mr: 2,
                                borderRadius: 5,
                                fontSize: "2vh",
                                background: "#002252",
                                boxShadow: "none",
                                width: "8em",
                                "&:hover": {
                                  backgroundColor: "#002252",
                                  boxShadow: "none",
                                },
                              }}
                              onClick={handleSaveClick}
                            >
                              Save
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                mt: 5,
                                mb: 2,
                                borderRadius: 5,
                                backgroundColor: "transparent",
                                color: "black",
                                width: "8em",
                                fontSize: "2.1vh",
                                border: "2px solid black",
                                boxShadow: "none",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                },
                              }}
                              onClick={handleCancelClick}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            sx={{
                              mt: 5,
                              mb: 2,
                              borderRadius: 5,
                              fontSize: "2.1vh",
                              background: "#002252",
                              boxShadow: "none",
                              width: "8em",
                              "&:hover": {
                                backgroundColor: "#002252",
                                boxShadow: "none",
                              },
                            }}
                            disabled={openPopup}
                            onClick={handleEditClick}
                          >
                            Edit
                          </Button>
                        )}
                      </Grid>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Dialog open={openPopup}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
                helperText={passwordError}
              />
              <TextField
                margin="dense"
                label="Confirm Password"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePopup}
                sx={{
                  mt: 5,
                  mb: 2,
                  mr: 2,
                  borderRadius: 5,
                  fontSize: "2.1vh",
                  backgroundColor: "none",
                  color: "black",
                  boxShadow: "none",
                  width: "8em",
                  "&:hover": {
                    backgroundColor: "gray",
                    boxShadow: "none",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleResetPassword}
                sx={{
                  mt: 5,
                  mb: 2,
                  borderRadius: 5,
                  fontSize: "2.1vh",
                  backgroundColor: "#002252",
                  boxShadow: "none",
                  width: "8em",
                  "&:hover": {
                    backgroundColor: "#002252",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default TechnicianProfile;
