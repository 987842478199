import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Collapse,
  IconButton,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { request, axiosInstance2 } from "../../config/axiosConfig";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import debounce from "lodash.debounce";

export default function TechnicianPaymentList() {
  const [technicianPayments, setTechnicianPayments] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [inquiryDetails, setInquiryDetails] = useState({});
  const MySwal = withReactContent(Swal);
  const [updatingPayment, setUpdatingPayment] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isInquiryDataLoading, setInquiryDataLoading] = useState(false);
  const size = 8;
  const tableContainerRef = useRef(null);

  useEffect(() => {
    fetchTechnicianPayment(page);
  }, [page]);

  const fetchTechnicianPayment = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/paginatedTechnicianPaymentList",
        null,
        { page: pageNumber, size: size }
      );
      const newTechnicianPayments = response.data.content;
      setHasMore(newTechnicianPayments.length > 0);
      setTechnicianPayments((prev) =>
        pageNumber === 0
          ? newTechnicianPayments
          : [...prev, ...newTechnicianPayments]
      );
    } catch (error) {
      console.error("Error fetching payment details: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedScrollHandler = useMemo(
    () =>
      debounce((e) => {
        if (
          e.target.scrollHeight - e.target.scrollTop <=
            e.target.clientHeight + 1 &&
          hasMore
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 300),
    [hasMore]
  );

  useEffect(() => {
    return () => debouncedScrollHandler.cancel();
  }, [debouncedScrollHandler]);

  const handleRowClick = async (id) => {
    setInquiryDataLoading(true);
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));

    if (!inquiryDetails[id]) {
      try {
        const response = await request(
          axiosInstance2,
          "GET",
          "/getCustomerInquiriesByGroupId",
          null,
          { id }
        );
        setInquiryDetails((prev) => ({ ...prev, [id]: response.data }));
      } catch (error) {
        console.error("Error fetching inquiry details:", error);
      }
    }
    setInquiryDataLoading(false);
  };

  const handlePaymentDone = async (id) => {
    setUpdatingPayment((prev) => ({ ...prev, [id]: true }));
    try {
      await request(axiosInstance2, "PUT", `/donePayment/${id}`);
      MySwal.fire({
        icon: "success",
        title: "Accepted",
        text: "Payment done successfully",
      });

      setTechnicianPayments((prev) =>
        prev.map((p) => (p.id === id ? { ...p, paymentStatus: "Done" } : p))
      );
    } catch (error) {
      console.error(error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error doing payment.",
      });
    } finally {
      setUpdatingPayment((prev) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <div>
      <div className="flex flex-col px-4 pt-3 lg:px-16">
        <Typography variant="h5" fontWeight="bold" className="p-2">
          Technician Payments List
        </Typography>
      </div>
      {loading && page === 0 ? (
        <div className="flex justify-center items-center mt-10">
          <CircularProgress />
        </div>
      ) : (
        <Grid display="flex" flexDirection="row" justifyContent="center">
          <TableContainer
            ref={tableContainerRef}
            component={Paper}
            sx={{
              marginTop: 2,
              maxHeight: "560px",
              maxWidth: "95%",
              overflowY: "scroll",
            }}
            onScroll={debouncedScrollHandler}
          >
            <Table
              sx={{ minWidth: 650, maxWidth: "100%", marginTop: 1 }}
              aria-label="technician payments"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <b>ID</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Name</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Type of expertise</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Contact Number</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Start Date</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>End Date</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Amount</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>No of inquiries</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {technicianPayments.map((payment) => (
                  <React.Fragment key={payment.id}>
                    <TableRow>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleRowClick(payment.id)}
                        >
                          {expandedRows[payment.id] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{payment.id}</TableCell>
                      <TableCell align="center">
                        {payment.technicianId.firstName}{" "}
                        {payment.technicianId.lastName}
                      </TableCell>
                      <TableCell align="center">
                        {payment.technicianId.typeOfExpertise}
                      </TableCell>
                      <TableCell align="center">
                        {payment.technicianId.contactNumber}
                      </TableCell>
                      <TableCell align="center">{payment.weekStart}</TableCell>
                      <TableCell align="center">{payment.weekEnd}</TableCell>
                      <TableCell align="center">{payment.total}</TableCell>
                      <TableCell align="center">
                        {payment.noOfInquiries}
                      </TableCell>
                      <TableCell align="center">
                        {payment.paymentStatus === "Done" ? (
                          <button
                            className="px-3 py-2 text-sm font-semibold text-white bg-green-500 border-2 border-green-500 rounded-full"
                            disabled
                          >
                            Paid
                          </button>
                        ) : (
                          <button
                            className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
                            onClick={() => handlePaymentDone(payment.id)}
                            disabled={updatingPayment[payment.id]}
                          >
                            {updatingPayment[payment.id]
                              ? "Please wait..."
                              : "Payment Done"}
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                        sx={{ backgroundColor: "#eeeeee" }}
                      >
                        <Collapse
                          in={expandedRows[payment.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={0}>
                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                {isInquiryDataLoading ? (
                                  <Box ml={10} my={2}>
                                    Loading...
                                  </Box>
                                ) : (
                                  inquiryDetails[payment.id]?.map((inquiry) => (
                                    <Box key={inquiry.id} pl={18} mt={2} mb={3}>
                                      <Grid container spacing={4}>
                                        <Grid item xs={3}>
                                          <strong>Customer Name:</strong>
                                          {"  "}
                                          {inquiry.firstName +
                                            " " +
                                            inquiry.lastName}
                                        </Grid>
                                        <Grid item xs={3}>
                                          <strong>Paid Date:</strong>{" "}
                                          {inquiry.paidDate}
                                        </Grid>
                                        <Grid item xs={2}>
                                          <strong>Service Price:</strong>{" "}
                                          {inquiry.servicePrice}
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                      </Grid>
                                    </Box>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </div>
  );
}
