import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  request,
  setAuthHeader,
  axiosInstance1,
  axiosInstance3,
} from "../../config/axiosConfig";
import { useLocation } from 'react-router-dom';

import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import NewNavBar from "../../components/appBar/NewNavBar";

const TechnicianProfileBL = () => {
  const [formData, setFormData] = useState({
    licenseNumber: "",
    licenseExpireDate: "",
    contractorNumber: "",
    contractorExpireDate: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [licenseCopyFile, setLicenseCopyFile] = useState(null);
  const [licenseCopyThumbnail, setLicenseCopyThumbnail] = useState(null);

  const [contractorCopyFile, setContractorCopyFile] = useState(null);
  const [contractorCopyThumbnail, setContractorCopyThumbnail] = useState(null);

  const [technicianPhoto, setTechnicianPhoto] = useState(null);
  const [technicianPhotoThumbnail, setTechnicianPhotoThumbnail] =
    useState(null);

  const [technicianSignature, setTechnicianSignature] = useState(null);
  const [technicianSignatureThumbnail, setTechnicianSignatureThumbnail] =
    useState(null);

  const [technician, setTechnician] = useState();
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const { userId } = location.state; 
  const { message } = location.state;

  useEffect(() => {
    if (userId) {
      fetchTechnician();
    }
  }, [userId]);
  
  const convertImage = (imageData, fileName = "image.jpg") => {
    if (imageData && imageData !== "null" && imageData !== "undefined") {
      const base64 = `data:image/jpeg;base64,${imageData}`;
      
      // Convert base64 to a File object
      const byteString = atob(imageData);
      const mimeType = "image/jpeg";
      const arrayBuffer = new Uint8Array(byteString.length);
      
      for (let i = 0; i < byteString.length; i++) {
        arrayBuffer[i] = byteString.charCodeAt(i);
      }
      
      const file = new File([arrayBuffer], fileName, { type: mimeType });
  
      return { base64, file };
    }
    
    return { base64: null, file: null };
  };
  
  
  const fetchTechnician = async () => {
      try {
        console.log("Requesting technician data ...");
        const response = await request(
          axiosInstance1,
          "GET",
          `/getTechnicianDetails/${userId}`
        );
        setFormData(response.data);
        setTechnician(response.data);
        const licenseCopy = convertImage(response.data.licenseCopy, "license_copy.jpg");
        setLicenseCopyFile(licenseCopy.file);
        setLicenseCopyThumbnail(licenseCopy.base64);
        
        const contractorCopy = convertImage(response.data.contractorCopy, "contractor_copy.jpg");
        setContractorCopyFile(contractorCopy.file);
        setContractorCopyThumbnail(contractorCopy.base64);
        
        const technicianPhoto = convertImage(response.data.technicianPhoto, "technician_photo.jpg");
        setTechnicianPhoto(technicianPhoto.file);
        setTechnicianPhotoThumbnail(technicianPhoto.base64);
        
        const technicianSignature = convertImage(response.data.technicianSignature, "technician_signature.jpg");
        setTechnicianSignature(technicianSignature.file);
        setTechnicianSignatureThumbnail(technicianSignature.base64);        

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching technicians:", error);
      }
    };

  const handleFileChange = (event, setFile, setThumbnail) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const thumbnailUrl = URL.createObjectURL(file);
      setThumbnail(thumbnailUrl);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Basic form validation
    if (   
      !formData.licenseNumber ||    
      !formData.licenseExpireDate ||
      !formData.contractorNumber ||
      !formData.contractorExpireDate
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }
  

  
    // Check if required files are selected
    if (!licenseCopyFile) {
      setErrorMessage("Please upload the license copy.");
      return;
    }

    if(!contractorCopyFile){
      setErrorMessage("Please upload the contractor copy.");
    }

  
    if (!technicianPhoto) {
      setErrorMessage("Please upload the technician photo.");
      return;
    }
  
    if (!technicianSignature) {
      setErrorMessage("Please upload the technician signature.");
      return;
    }

    const {...dataToSubmit } = formData;
  
    const formDataWithFile = new FormData();
    formDataWithFile.append("technician", JSON.stringify(dataToSubmit));
    formDataWithFile.append("licenseCopyFile", licenseCopyFile);
    formDataWithFile.append("contractorCopyFile", contractorCopyFile);
    formDataWithFile.append("technicianPhoto", technicianPhoto);
    formDataWithFile.append("technicianSignature", technicianSignature);
  
    setIsSubmitting(true);
    setErrorMessage("");
    try {
      const response = await request(
        axiosInstance3,
        "POST",
        `/updateTechnician/${userId}`,
        formDataWithFile
      );
  
      Swal.fire({
        icon: "success",
        title: "Update Successful",
        text: "Your details have been successfully updated.",
        confirmButtonText: "OK",
      }).then(() => {
        localStorage.setItem("user", JSON.stringify(response.data));
              localStorage.setItem("userid", userId);
              localStorage.setItem("firstName", technician.firstName);
              localStorage.setItem("lastName", technician.lastName);
              localStorage.setItem("companyId", technician.userId.companyId);
              setAuthHeader(response.data.token);
        
              navigate("/portal/technicianDashboard");
      });
  
      setFormData({
        licenseNumber: "",
        licenseExpireDate: "",
        contractorNumber: "",
        contractorExpireDate: "",
      });
      setLicenseCopyFile(null);
      document.getElementById("licenseCopyFile").value = "";
      setContractorCopyFile(null);
      if (document.getElementById("contractorCopyFile")) {
        document.getElementById("contractorCopyFile").value = "";
      }
      setTechnicianPhoto(null);
      document.getElementById("technicianPhoto").value = "";
      setTechnicianSignature(null);
      document.getElementById("technicianSignature").value = "";
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const handleClearForm = () => {
    setFormData({
      licenseNumber: "",
      licenseExpireDate: "",
      contractorNumber: "",
      contractorExpireDate: "",
    });
    setLicenseCopyFile(null);
    document.getElementById("licenseCopyFile").value = "";
    setContractorCopyFile(null);
    document.getElementById("contractorCopyFile").value = "";
    setTechnicianPhoto(null);
    document.getElementById("technicianPhoto").value = "";
    setTechnicianSignature(null);
    document.getElementById("technicianSignature").value = "";
    setSuccessMessage("");
    setErrorMessage("");
    setLicenseCopyThumbnail(null);
    setContractorCopyThumbnail(null);
    setTechnicianPhotoThumbnail(null);
    setTechnicianSignatureThumbnail(null);
  };

  return (
    <>
          <NewNavBar/>
      <Container component="main">
        <Paper
          sx={{
            width: "90%",
            maxWidth: 800,
            padding: 3,
            margin: "auto",
            marginTop: 5,
          }}
        >
              <Typography sx={{ color: "red", textAlign: "left" }}>
                  {message}
                </Typography>
          <Box
            sx={{
              padding: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "8px", 
            }}
          >
            <div className="text-lg font-bold text-center text-[#002252] lg:text-2xl">
              Technician Edit Details
            </div>
          </Box>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
            className="pt-10"
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> License Number:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="licenseNumber"
                    name="licenseNumber"
                    autoComplete="off"
                    value={formData.licenseNumber}
                    disabled
                  />
                </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontSize: "2vh" }}>
                        <span style={{ color: "red" }}>*</span> License Expire
                        Date:
                      </InputLabel>
                      <TextField
                        size="small"
                        required
                        fullWidth
                        id="licenseExpireDate"
                        name="licenseExpireDate"
                        type="date"
                        autoComplete="off"
                        value={formData.licenseExpireDate}
                        onChange={handleChange}
                        InputProps={{
                          inputProps: {
                            min: new Date().toISOString().split("T")[0],
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontSize: "2vh" }}>
                        <span style={{ color: "red" }}>*</span> Contractor
                        Number:
                      </InputLabel>
                      <TextField
                        size="small"
                        required
                        fullWidth
                        id="contractorNumber"
                        name="contractorNumber"
                        autoComplete="off"
                        value={formData.contractorNumber}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontSize: "2vh" }}>
                        <span style={{ color: "red" }}>*</span> Contractor
                        Expire Date:
                      </InputLabel>
                      <TextField
                        size="small"
                        required
                        fullWidth
                        id="contractorExpireDate"
                        name="contractorExpireDate"
                        type="date"
                        autoComplete="off"
                        value={formData.contractorExpireDate}
                        onChange={handleChange}
                        InputProps={{
                          inputProps: {
                            min: new Date().toISOString().split("T")[0],
                          },
                        }}
                      />
                    </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> License Copy:
                  </InputLabel>
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}
                  >
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="licenseCopyFile"
                    name="licenseCopyFile"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        setLicenseCopyFile,
                        setLicenseCopyThumbnail
                      )
                    }
                  />
                  {licenseCopyThumbnail && (
                    <img
                      src={licenseCopyThumbnail}
                      alt="License Copy"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
                {formData.typeOfExpertise === "Electrical" && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontSize: "2vh" }}>
                        <span style={{ color: "red" }}>*</span> Contractor Copy:
                      </InputLabel>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "red",
                          fontSize: "1.5vh",
                          marginTop: "4px",
                        }}
                      >
                        Maximum image size 2MB
                      </Typography>
                      <input
                        type="file"
                        id="contractorCopyFile"
                        name="contractorCopyFile"
                        accept="image/*"
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            setContractorCopyFile,
                            setContractorCopyThumbnail
                          )
                        }
                      />
                      {contractorCopyThumbnail && (
                        <img
                          src={contractorCopyThumbnail}
                          alt="Contractor Copy"
                          style={{
                            width: "200px",
                            height: "100px",
                            marginTop: "10px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Photo of the
                    Technician:
                  </InputLabel>
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}
                  >
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="technicianPhoto"
                    name="technicianPhoto"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        setTechnicianPhoto,
                        setTechnicianPhotoThumbnail
                      )
                    }
                  />
                  {technicianPhotoThumbnail && (
                    <img
                      src={technicianPhotoThumbnail}
                      alt="Technician Profile"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Image of the
                    Signature:
                  </InputLabel>
                  <Typography
                    variant="body2"
                    sx={{ color: "red", fontSize: "1.5vh", marginTop: "4px" }}
                  >
                    Maximum image size 2MB
                  </Typography>
                  <input
                    type="file"
                    id="technicianSignature"
                    name="technicianSignature"
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        setTechnicianSignature,
                        setTechnicianSignatureThumbnail
                      )
                    }
                  />
                  {technicianSignatureThumbnail && (
                    <img
                      src={technicianSignatureThumbnail}
                      alt="Technician Signature"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "10px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              {/* Success message */}
              {successMessage && (
                <Typography sx={{ color: "green", textAlign: "center" }}>
                  {successMessage}
                </Typography>
              )}
              {/* Error message */}
              {errorMessage && (
                <Typography sx={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}
              <Button
                className="bg-[#002252]"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 2,
                  backgroundColor: "#002252", 
                  color: "#FFFFFF", 
                  "&:hover": {
                    backgroundColor: "#001d40", 
                  },
                }}
                disabled={isSubmitting} 
              >
                {isSubmitting ? "Saving, please wait..." : "Save Changes"}{" "}            
              </Button>
              <Button
                onClick={handleClearForm}
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                  mb: 2,
                  borderRadius: 2,
                  borderColor: "#FCCF00", 
                  color: "#FCCF00", 
                  "&:hover": {
                    borderColor: "#e6ba00", 
                    color: "#e6ba00", 
                  },
                }}
              >
                Clear Form
              </Button>
            </form>
          </div>
        </Paper>
      </Container>
    </>
  );
};

export default TechnicianProfileBL;
