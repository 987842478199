import React, { useState, useEffect, useRef } from "react";
import {
  request,
  axiosInstance2,
  axiosInstance1,
} from "../../config/axiosConfig";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Stripe from "react-stripe-checkout";
import CustomerViewDetailsPopup from "../../components/dashboard/CustomerViewDetails";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function CustomerDashboardPage() {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isCustomerActive, setIsCustomerActive] = useState(null);
  const [expandedGroup, setExpandedGroup] = useState({});
  const [viewDetailsLoading, setViewDetailsLoading] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [withButton, setWithButton] = useState(false);
  const [amount, setAmount] = useState(null);
  const [inquiryGroupId, setInquiryGroupId] = useState(null);
  const [totalInquiries, setTotalInquiries] = useState(0);
  const [pendingInquiries, setPendingInquiries] = useState(0);
  const [ongoingInquiries, setOngoingInquiries] = useState(0);
  const [completeInquiries, setCompleteInquiries] = useState(0);
  const [dots, setDots] = useState("");
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem("userid");
  const user = localStorage.getItem("firstName");
  const welcomeUser = user.charAt(0).toUpperCase() + user.slice(1);
  const MySwal = withReactContent(Swal);
  const url = window.location.hash;
  const params = new URLSearchParams(url.split("?")[1]);
  const token = params.get("token");
  const stripeButtonRef = useRef();

  useEffect(() => {
    fetchCustomers();
    fetchCustomerData();

        const interval = setInterval(() => {
          setDots((prev) => (prev.length < 3 ? prev + "." : ""));
        }, 500); 
    
        fetchCustomerData().finally(() => {
          clearInterval(interval); 
          setLoading(false);
        });
    
        return () => clearInterval(interval);
  }, []); 
  
  useEffect(() => {
    // If token is available, decode it and set the groupId and amount
    if (token) {
      const decodedToken = atob(token);
      const [id, amount] = decodedToken.split(":");
      const intId = parseInt(id, 10);
  
      // Only set these values if token is valid
      setInquiryGroupId(intId);
      setAmount(amount);
      setShowPaymentPopup(true);
      setWithButton(false);
    } else {
      // Token is empty, handle the dashboard state accordingly
      setShowPaymentPopup(false);
      console.log("Token is empty, showing dashboard normally.");
    }
  }, [token]); // Dependency array includes token to trigger this effect when token changes
  
  const fetchCustomerData = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getCustomerDetailsByUserId/${userId}`
      );
      if (response.data.status === "Active") {
        setIsCustomerActive(true);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };
  
  // Fetching customer and inquiry data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const totInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalCustomerInquiries",
          null,
          { userid: userId }
        );
        setTotalInquiries(totInqResponse.data);
  
        const penbInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalPendingCustomerInquiries",
          null,
          { userid: userId }
        );
        setPendingInquiries(penbInqResponse.data);
  
        const ongoInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalOngoingCustomerInquiries",
          null,
          { userid: userId }
        );
        setOngoingInquiries(ongoInqResponse.data);
  
        const comInqResponse = await request(
          axiosInstance2,
          "GET",
          "/getTotalCompleteCustomerInquiries",
          null,
          { userid: userId }
        );
        setCompleteInquiries(comInqResponse.data);
      } catch (error) {
        console.error("Error fetching inquiry data:", error);
      }
    };
  
    fetchData();
  }, [userId]); // Fetch data when `userId` changes
  
  const fetchCustomers = async () => {
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/getCustomerInquiriesByUserid",
        null,
        { userid: userId }
      );
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  
  // Handle closing the payment popup
  const handleClosePayment = () => {
    setShowPaymentPopup(false);
    const hash = window.location.hash;
    const [base, queryString] = hash.split("?");
  
    if (queryString) {
      const params = new URLSearchParams(queryString);
      params.delete("token");
  
      const newHash = params.toString() ? `${base}?${params.toString()}` : base;
      window.history.replaceState({}, document.title, newHash);
    }
  };
  
  // Open the details popup
  const openDetailsPopup = async (overallStatus, inquiryData) => {
    setViewDetailsLoading((prev) => ({ ...prev, [inquiryData.customerInquiryId]: true }));
  
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        `/getInquiryDetailsById/${inquiryData.customerInquiryId}`
      );
      console.log("inquiry id: ", inquiryData.customerInquiryId);
      setSelectedCustomer(response.data);
      console.log("inquiry data: ", selectedCustomer);
      setSelectedTechnician(response.data.technicianId);
      setCurrentStatus(overallStatus);
      setSelectedStatus(overallStatus);
      setShowDetailsPopup(true);
    } catch (error) {
      console.error("Error fetching inquiry details:", error);
    } finally {
      setViewDetailsLoading((prev) => ({ ...prev, [inquiryData.customerInquiryId]: false }));
    }
  };
  
  // Close the details popup
  const closeDetailsPopup = () => {
    setSelectedTechnician(null);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
    setViewDetailsLoading({});
  };
  
  // Toggle dropdown for groups
  const toggleDropdown = (groupId) => {
    setExpandedGroup((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId],
    }));
  };
  
  // Group customer inquiries by groupId
  const groupedInquiries = customers.reduce((groups, customer) => {
    const groupId = customer.groupId.groupId;
    if (!groups[groupId]) {
      groups[groupId] = [];
    }
    groups[groupId].push(customer);
    return groups;
  }, {});
  
  // Check if all inquiries in a group are accepted
  const isAllAccepted = (group) => group.every((inquiry) => inquiry.overallStatus === "Accepted");
  
  // Calculate total price of a group of inquiries
  const calculateTotalPrice = (group) => group.reduce((total, inquiry) => total + inquiry.servicePrice, 0);
  
  // Handle direct group payment
  const handleGroupPaymentDirect = async (stripetoken) => {
    const inquiryList = customers.filter(
      (customer) => customer.groupId.groupId === inquiryGroupId
    );
  
    const paymentData = {
      token: stripetoken.id,
      inquiries: inquiryList.map((inquiry) => ({
        inquiryId: inquiry.customerInquiryId,
        price: inquiry.servicePrice,
      })),
    };
    console.log(paymentData);
  
    try {
      const response = await request(
        axiosInstance2,
        "POST",
        "api/payment/charge",
        paymentData
      );
      console.log("Payment response: ", response);
  
      fetchCustomers();
      MySwal.fire({
        icon: "success",
        title: "Success",
        text: "Your payment has been processed successfully.",
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
  
      console.log("Error: ", error);
    } finally {
      handleClosePayment();
    }
  };
  
  // Handle group payment
  const handleGroupPayment = async (stripetoken, group) => {
    setIsSubmitting(true);
  
    const paymentData = {
      token: stripetoken.id,
      inquiries: group.map((inquiry) => ({
        inquiryId: inquiry.customerInquiryId,
        price: inquiry.servicePrice,
      })),
    };
    console.log(paymentData);
  
    try {
      const response = await request(
        axiosInstance2,
        "POST",
        "api/payment/charge",
        paymentData
      );
      console.log("Payment response: ", response);
  
      fetchCustomers();
      MySwal.fire({
        icon: "success",
        title: "Success",
        text: "Your payment has been processed successfully.",
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
  
      console.log("Error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div div className="flex flex-col bg-gray-300">
      <div className="flex flex-col px-4 pt-10 lg:px-16 ">
        <div className="text-2xl font-bold text-gray-800 sm:text-3xl lg:text-4xl">
          Welcome back {welcomeUser}
        </div>
        <div className="text-sm sm:text-base">
          Check your last activity today
        </div>
      </div>

      {isCustomerActive === null? (
        <div className="text-gray-500 font-bold text-xl text-center py-5">
          Loading{dots}
        </div>  
      ) : (
      <>
      {isCustomerActive ? (
        <>
          <div className="flex flex-col items-center w-full h-auto gap-4 mt-10 lg:mt-1 lg:px-16">
            {/* Mobile View (1 column of 4 boxes) */}
            <div className="flex flex-col w-full gap-4 px-4 lg:hidden">
              {/* Box 1 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Total Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {totalInquiries}
                  </div>
                </div>
              </div>
              {/* Box 2 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Pending Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {pendingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 3 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Ongoing Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {ongoingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 4 */}
              <div className="w-full h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                    Completed Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {completeInquiries}
                  </div>
                </div>
              </div>
            </div>

            {/* Large Screen View (1 row of 4 boxes) */}
            <div className="hidden w-full gap-4 px-1 lg:flex">
              {/* Box 1 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Total Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {totalInquiries}
                  </div>
                </div>
              </div>
              {/* Box 2 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Pending Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {pendingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 3 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Ongoing Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {ongoingInquiries}
                  </div>
                </div>
              </div>
              {/* Box 4 */}
              <div className="w-1/4 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
                <div className="flex flex-col items-center w-full h-full">
                  <div className="text-base font-semibold text-center text-white lg:text-lg sm:text-xl">
                    Completed Inquiries
                  </div>
                  <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                    {completeInquiries}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-start w-full px-4 mt-2 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
            Ongoing Inquiries
          </div>

          <div className="overflow-y-auto max-h-[380px] mb-4">
            {Object.keys(groupedInquiries).map((groupId) => {
              const group = groupedInquiries[groupId];
              const totalPrice = calculateTotalPrice(group);
              const allAccepted = isAllAccepted(group);
              return (
                <div
                  key={groupId}
                  className="flex flex-col items-center w-full px-2 mb-4 lg:px-16"
                >
                  {/* Group Card */}
                  <div className="w-screen px-32 lg:px-40">
                    <div className="flex flex-col justify-center w-screen p-4 bg-white rounded-lg lg:w-full">
                      {/* Common Details */}
                      <div className="flex flex-col justify-between w-full lg:flex-row">
                        <div className="text-xl font-bold">
                          Group ID: {groupId}
                        </div>
                        <div className="flex flex-row text-xl font-bold">
                          <div className="pr-1">
                            {groupedInquiries[groupId][0].firstName}{" "}
                            {groupedInquiries[groupId][0].lastName}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between mt-2 lg:flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-row items-center">
                            <div className="text-sm font-bold lg:text-base">
                              Order Date:
                            </div>
                            <div className="pl-2 text-sm lg:text-base font-base">
                              {groupedInquiries[groupId][0].inquiryDate}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <div className="text-sm font-bold lg:text-base">
                              Preferred Date:
                            </div>
                            <div className="pl-2 text-sm lg:text-base font-base">
                              {groupedInquiries[groupId][0].serviceDate}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <div className="text-sm font-bold lg:text-base">
                              Preferred Time:
                            </div>
                            <div className="pl-2 text-sm lg:text-base font-base">
                              {groupedInquiries[groupId][0].serviceTime}
                            </div>
                          </div>
                        </div>

                        {/* Toggle Button */}
                        <div className="flex flex-col justify-between">
                          <div className="flex self-start">
                            {allAccepted && (
                              <Stripe
                                stripeKey="pk_live_51PFstdGnEP0APj6JG2SXQfxrvUfXq1EmmGnmYnVg50pocP0ONaSYh9CKe9paogS2qwzDD6uDHHUCynuuuTJF3SsL00nDX5vYX0"
                                token={(token) =>
                                  handleGroupPayment(token, group)
                                }
                                amount={totalPrice * 100}
                                description={`Payment for group ID: ${groupId}`}
                                currency="AUD"
                                closed={() => setIsSubmitting(false)}
                              >
                                <button
                                  className="w-32 py-2 mt-2 font-semibold text-white bg-[#002252] rounded-full"
                                  disabled={isSubmitting}
                                  onClick={() => setIsSubmitting(true)}
                                >
                                  {isSubmitting
                                    ? "Processing..."
                                    : "Pay With Card"}
                                  {/* (Total: ${totalPrice}) */}
                                </button>
                              </Stripe>
                            )}
                          </div>
                          <div className="flex justify-end mt-2 align-bottom">
                            <button
                              className="px-3"
                              onClick={() => toggleDropdown(groupId)}
                            >
                              {expandedGroup[groupId] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Dropdown for Inquiries */}
                      {expandedGroup[groupId] && (
                        <div className="mt-4">
                          {groupedInquiries[groupId].map(
                            (customerData, index) => (
                              <div
                                key={index}
                                className="flex flex-col p-2 mt-2 bg-gray-100 rounded-lg"
                              >
                                {/* Individual Inquiry Details */}
                                <div className="flex flex-row justify-between">
                                  <div>
                                    <div className="flex flex-row items-center">
                                      <div className="text-sm font-bold lg:text-base">
                                        Order ID:
                                      </div>
                                      <div className="pl-2 text-sm lg:text-base font-base">
                                        {customerData.customerInquiryId}
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-center">
                                      <div className="text-sm font-bold lg:text-base">
                                        Service Type:
                                      </div>
                                      <div className="pl-2 text-sm lg:text-base font-base">
                                        {customerData.type}
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-center mt-2">
                                      <div className="text-sm font-bold lg:text-base">
                                        Status:
                                      </div>
                                      <div className="ml-2 px-3 py-0.5 text-sm font-bold lg:text-base font-base bg-yellow-700 rounded-full">
                                        {customerData.overallStatus}
                                      </div>
                                    </div>
                                  </div>

                                  {/* View Details Button */}
                                  {(customerData.overallStatus === "Paid" ||
                                    customerData.overallStatus ===
                                    "Completed") && (
                                      <div className="flex items-center">
                                        <button
                                          className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
                                          onClick={() =>
                                            openDetailsPopup(
                                              customerData.overallStatus,
                                              customerData
                                            )
                                          }
                                          disabled={viewDetailsLoading[customerData.customerInquiryId]}
                                          >
                                            {viewDetailsLoading[customerData.customerInquiryId]
                                                            ? "Viewing..."
                                                            : "View Details"}
                                        </button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    {showDetailsPopup && (
                      <CustomerViewDetailsPopup
                        closeDetailsPopup={closeDetailsPopup}
                        selectedTechnician={selectedTechnician}
                        overallStatus={currentStatus}
                        selectedCustomer={selectedCustomer}
                      />
                    )}
                  </div>
                </div>
              );
            })}

            {showPaymentPopup && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative p-6 bg-white rounded-lg shadow-lg w-1/7">
                  <button
                    className="absolute text-gray-500 top-2 right-2 hover:text-gray-700"
                    onClick={() => setShowPaymentPopup(false)}
                  >
                    &times;
                  </button>
                  <h2 className="mb-4 text-xl font-semibold text-center">
                    Complete Your Payment
                  </h2>
                  <div className="flex flex-col items-center">
                    <Stripe
                      stripeKey="pk_live_51PFstdGnEP0APj6JG2SXQfxrvUfXq1EmmGnmYnVg50pocP0ONaSYh9CKe9paogS2qwzDD6uDHHUCynuuuTJF3SsL00nDX5vYX0"
                      token={(token) => handleGroupPaymentDirect(token)}
                      amount={amount * 100}
                      description={`Payment for group ID: ${inquiryGroupId}`}
                      currency="AUD"
                      closed={() => {
                        handleClosePayment();
                      }}
                      ref={stripeButtonRef}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>

      ) : (
        <Typography
          variant="h6"
          color="error"
          align="center"
          sx={{ marginTop: 2 }}
        >
          Your account is currently inactive. Please wait for admin approval.
        </Typography>
      )}
      </>
      )}
      <br></br>
    </div>
  );
}

export default CustomerDashboardPage;
