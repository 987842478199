import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import {
  request,
  setAuthHeader,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Link,
} from "@mui/material";
import NewNavBar from "../../components/appBar/NewNavBar";

const LoginPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [validateError, setValidateError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = window.location.hash;
  const params = new URLSearchParams(url.split("?")[1]);

  const token = params.get("token");
  console.log("token", token);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.login) newErrors.login = "Username is required";
    if (!formData.password) newErrors.password = "Password is required";
    setValidateError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const roleId = JSON.parse(user).roleId;
      if (token) {
        if (roleId === 2 || roleId === 1) {
          navigate(`/portal/customerDashboard?token=${token}`);
        } else {
          console.error("Unauthorized access: Not a Customer");
        }
      } else {
        navigate("/portal/customerDashboard");
      }
    }
  }, [navigate]);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError("");
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    // Clear localStorage
    localStorage.clear();
    const { login, password } = formData;
    if (!validateForm()) return;
    setIsSubmitting(true);
    const roleId = 2;
    try {
      const response = await request(
        axiosInstance1,
        "POST",
        `/login/${roleId}`,
        {
          login,
          password,
        }
      );

      console.log("Login response:", response.data);

      if (!response.data) {
        throw new Error("Invalid response data");
      }

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userid", response.data.id);
      localStorage.setItem("firstName", response.data.firstName);
      localStorage.setItem("lastName", response.data.lastName);
      localStorage.setItem("companyId", response.data.companyId);
      setAuthHeader(response.data.token);

      if (token) {
        navigate(`/portal/customerDashboard?token=${token}`);
      } else {
        navigate("/portal/customerDashboard");
      }
    } catch (error) {
      console.error("Login error:", error);

      if (error.response) {
        setError(error.response.data.message);
        console.error("Error message:", error.response.data.message);
        console.error("Status code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  console.log("error", error);

  const handleClick = (type) => {
    localStorage.setItem("userType", type);
    navigate("/forgotPassword");
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(to right, #FCCF00,#002252, #002252)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "160px",
            left: "-125px",
            width: "250px",
            height: "250px",
            background: "rgba(255, 255, 255, 0.2)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-250px",
            left: "20px",
            width: "600px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            right: "-200px",
            width: "300px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <NewNavBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 2,
                borderRadius: 5,
                padding: 4,
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  mt: 3,
                }}
              >
                <span
                  style={{
                    borderBottom: "3px solid #002252",
                    mr: "-4px",
                    pr: "4px",
                    color: "#FCCF00",
                  }}
                >
                  Customer {"\u00A0"}
                </span>
                <span
                  style={{
                    borderBottom: "3px solid #FCCF00",
                    color: "#002252",
                    ml: "-4px",
                    pl: "4px",
                  }}
                >
                  Login
                </span>
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={onSubmitLogin} // Change the onSubmit function
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      fullWidth
                      id="loginName"
                      name="login"
                      label="Username"
                      autoComplete="family-name"
                      onChange={onChangeHandler}
                      error={Boolean(validateError.login)}
                      helperText={validateError.login}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="password"
                      required
                      fullWidth
                      id="loginPassword"
                      name="password"
                      label="Password"
                      autoComplete="family-name"
                      onChange={onChangeHandler}
                      error={Boolean(validateError.password)}
                      helperText={validateError.password}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      visibility: error ? "visible" : "hidden",
                      height: "1px",
                    }}
                  >
                    {error && (
                      <Typography color="error" variant="body2">
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    sx={{ textAlign: "right" }}
                  >
                    <Link
                      onClick={() => handleClick("customer")}
                      variant="body2"
                      sx={{
                        color: "#002252",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Forgot Password ?
                    </Link>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 3,
                    backgroundColor: "#002252",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#001d40",
                    },
                    textTransform: "none",
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "LOGIN"}
                </Button>
                <Grid container justifyContent="center">
                  <Grid item>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Not registered?{" "}
                    </span>

                    <Link
                      href="/"
                      variant="body2"
                      sx={{ color: "#002252", textDecoration: "none" }}
                    >
                      Register
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
