import React from "react";

const InquiryCard = ({ inquiryData, openDetailsPopup, viewDetailsLoading  }) => {
  return (
    <div className="flex flex-col items-center w-full px-2 mb-3 lg:px-16">
      {/* Card */}
      <div className="flex flex-col justify-center w-full p-4 bg-white rounded-lg">
        <div className="flex flex-col justify-between w-full lg:flex-row">
          <div className="flex flex-row text-xl font-bold">
            <div className="pr-1">{inquiryData.firstName}</div>
            <div className="pr-1">{inquiryData.lastName}</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="text-sm font-bold lg:text-lg">Type :</div>
            <div className="pl-2 text-sm lg:text-lg font-base">
              {inquiryData.type}
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between w-full mt-2 lg:flex-row">
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <div className="text-sm font-bold lg:text-base">Order ID :</div>
              <div className="pl-2 text-sm lg:text-base font-base">
                {inquiryData.customerInquiryId}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="text-sm font-bold lg:text-base">Order Date :</div>
              <div className="pl-2 text-sm lg:text-base font-base">
                {inquiryData.inquiryDate}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="text-sm font-bold lg:text-base">
                Preferred Date :
              </div>
              <div className="pl-2 text-sm lg:text-base font-base">
                {inquiryData.serviceDate}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="text-sm font-bold lg:text-base">Preferred Time :</div>
              <div className="pl-2 text-sm lg:text-base font-base">
                {inquiryData.serviceTime}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <div className="lg:text-base text-sm font-bold mr-2 mt-0.5">
              Status :
            </div>
            <div className="inline-block px-3 py-1 text-sm font-bold bg-yellow-700 rounded-full lg:text-base">
              {inquiryData.overallStatus}
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full mt-2 lg:justify-end">
          <div className="flex flex-row items-center">
            <button
              className="px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-full"
              onClick={() =>
                openDetailsPopup(
                  inquiryData.overallStatus,
                  inquiryData
                )
              }
              disabled={viewDetailsLoading[inquiryData.customerInquiryId]}
            >
              {viewDetailsLoading[inquiryData.customerInquiryId]
                              ? "Viewing..."
                              : "View Details"}
              {/* View Details */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryCard;
