import React, { useEffect, useState } from "react";
import CompletedInquiryCard from "../../components/dashboard/InquiryCard";
import TechnicianViewDetailsPopup from "../../components/dashboard/TechnicianViewDetails";
import {
  request,
  axiosInstance1,
  axiosInstance2,
} from "../../config/axiosConfig";
import { CircularProgress, Typography } from "@mui/material";
import { RiNurseFill } from "react-icons/ri";

const TechnicianCompletedInquiries = () => {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [isTechnicianActive, setIsTechnicianActive] = useState(RiNurseFill);
  const [loading, setLoading] = useState(true);
  const [dots, setDots] = useState("");

  const userId = localStorage.getItem("userid");

  useEffect(() => {
    fetchTechnician();
    fetchCustomers();
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);

    Promise.all([fetchTechnician(), fetchCustomers()]).finally(() => {
      clearInterval(interval);
      setLoading(false);
    });

    return () => clearInterval(interval);
  }, []);

  //getTechnicianData
  const fetchTechnician = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getTechnicianDetailsByUserId/${userId}`
      );
      if (response.data.status === "Active") {
        setIsTechnicianActive(true);
      }
    } catch (error) {
      console.error("Error fetching technicians:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await request(
        axiosInstance2,
        "GET",
        "/getTechnicianCompletedInquiries",
        null,
        { userid: userId }
      );
      setCustomers(response.data);
      console.log("response", response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDetailsPopup = (overallStatus, inquiryData) => {
    setShowDetailsPopup(true);
    setCurrentStatus(overallStatus);
    setSelectedStatus(overallStatus);
    setSelectedCustomer(inquiryData);
  };

  const closeDetailsPopup = () => {
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
  };

  return (
    <div className="flex flex-column justify-start w-full px-4 mt-4 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
      Completed Inquiries
      {isTechnicianActive === null ? (
  <div className="text-gray-500 font-bold text-xl text-center py-5">
    Loading{dots}
  </div>
) : isTechnicianActive ? (
  customers.length === 0 ? (
    <div className="flex items-left h-full mt-2">
      <p className="text-gray-500 text-lg font-semibold">
        You do not have completed inquiries.
      </p>
    </div>
  ) : (
    <div className="overflow-y-auto max-h-[500px] mb-4 mt-8">
      {customers.map((customerData, index) => (
        <CompletedInquiryCard
          key={index}
          inquiryData={customerData}
          openDetailsPopup={openDetailsPopup}
        />
      ))}
    </div>
  )
) : (
  <Typography
    variant="h6"
    color="error"
    align="center"
    sx={{ marginTop: 2 }}
  >
    Your account is currently inactive. Please wait for admin approval.
  </Typography>
)}

<br></br>

{showDetailsPopup && (
  <TechnicianViewDetailsPopup
    closeDetailsPopup={closeDetailsPopup}
    overallStatus={currentStatus}
    selectedCustomer={selectedCustomer}
  />
)}
    </div>
  );
};

export default TechnicianCompletedInquiries;
