import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  request,
  axiosInstance1,
  setAuthHeader,
  getAuthToken,
  axiosInstance2,
} from "../../config/axiosConfig"; // Assuming you have a request function for API calls
import { FaCheck } from "react-icons/fa";
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DevicesFoldSharp } from "@mui/icons-material";

const statuses = ["Pending", "Approved", "Accepted", "Paid", "Completed"];

function AdminDashboard() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAssignTaskPopup, setShowAssignTaskPopup] = useState(false);

  const [technicians, setTechnicians] = useState([]);
  const [assignTaskData, setAssignTaskData] = useState([]);
  const [selectedCustomerInquiryId, setSelectedCustomerInquiryId] =
    useState(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(statuses[2]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [viewDetailsLoading, setViewDetailsLoading] = useState({});

  useEffect(() => {
    fetchPendingCustomers();
    fetchApprovedCustomers();
    // fetchTechnicians();
    fetchDashboardData();
    fetchDashboardFooter();
  }, []);

  const [totalInquiries, setTotalInquiries] = useState(0);
  const [pendingInquiries, setPendingInquiries] = useState(0);
  const [ongoingInquiries, setOngoingInquiries] = useState(0);
  const [completeInquiries, setCompleteInquiries] = useState(0);

  const [activeCustomers, setActiveCustomers] = useState(0);
  const [inactiveCustomers, setInactiveCustomers] = useState(0);
  const [activeTechnicians, setActiveTechnicians] = useState(0);
  const [inactiveTechnicians, setInactiveTechnicians] = useState(0);

  const user = localStorage.getItem("firstName");
  const welcomeUser = user.charAt(0).toUpperCase() + user.slice(1);

  const MySwal = withReactContent(Swal);

  //getDashboardData
  const fetchDashboardData = async () => {
    try {
      const response = await request(
        axiosInstance2,
        "GET",
        "/getInquiriesSummary"
      );
      const {
        totalInquiries,
        totalPendingInquiries,
        totalOngoingInquiries,
        totalCompleteInquiries,
      } = response.data;

      setTotalInquiries(totalInquiries);
      setPendingInquiries(totalPendingInquiries);
      setOngoingInquiries(totalOngoingInquiries);
      setCompleteInquiries(totalCompleteInquiries);
    } catch (error) {
      console.error("Error fetching inquiries:", error);
    }
  };
  // const fetchDashboardData = async () => {
  //   try {
  //     const totInqResponse = await request(
  //       axiosInstance2,
  //       "GET",
  //       "/getTotalInquiries"
  //     );
  //     setTotalInquiries(totInqResponse.data);

  //     const penbInqResponse = await request(
  //       axiosInstance2,
  //       "GET",
  //       "/getTotalPendingInquiries"
  //     );
  //     setPendingInquiries(penbInqResponse.data);

  //     const ongoInqResponse = await request(
  //       axiosInstance2,
  //       "GET",
  //       "/getTotalOngoingInquiries"
  //     );
  //     setOngoingInquiries(ongoInqResponse.data);

  //     const comInqResponse = await request(
  //       axiosInstance2,
  //       "GET",
  //       "/getTotalCompleteInquiries"
  //     );
  //     setCompleteInquiries(comInqResponse.data);
  //   } catch (error) {
  //     console.error("Error fetching inquiries:", error);
  //   }
  // };

  const fetchDashboardFooter = async () => {
    try {
      const actviveCustomerResponse = await request(
        axiosInstance1,
        "GET",
        "/getActiveCustomersCount"
      );
      setActiveCustomers(actviveCustomerResponse.data);

      const inactviveCustomerResponse = await request(
        axiosInstance1,
        "GET",
        "/getInactiveCustomersCount"
      );
      setInactiveCustomers(inactviveCustomerResponse.data);

      const actviveTechnicianResponse = await request(
        axiosInstance1,
        "GET",
        "/getActiveTechniciansCount"
      );
      setActiveTechnicians(actviveTechnicianResponse.data);

      const inactviveTechnicianResponse = await request(
        axiosInstance1,
        "GET",
        "/getInactiveTechniciansCount"
      );
      setInactiveTechnicians(inactviveTechnicianResponse.data);
    } catch (error) {
      console.error("Error fetching footer data:", error);
    }
  };

  const [pendingCustomers, setPendingCustomers] = useState([]);
  const [pendingPage, setPendingPage] = useState(0);
  const pendingSize = 5;
  const [pendingLoading, setPendingLoading] = useState(false);

  const fetchPendingCustomers = async (page = 0, size = 5) => {
    try {
      if (page === 0) {
        setPendingLoading(true);
        const response = await request(
          axiosInstance2,
          "GET",
          "/customerPendingInquiryListPage",
          null,
          { page, size }
        );
        setPendingCustomers(response.data.content);
      } else {
        const response = await request(
          axiosInstance2,
          "GET",
          "/customerPendingInquiryListPage",
          null,
          { page, size }
        );
        setPendingCustomers((prevCustomers) => [
          ...prevCustomers,
          ...response.data.content,
        ]);
      }
    } catch (error) {
      console.error("Error fetching pending customers:", error);
    } finally {
      setPendingLoading(false);
      const isPendingDataEmpty = pendingCustomers.length === 0;
    }
  };

  const handlePendingScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !pendingLoading) {
      setPendingPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchPendingCustomers(pendingPage);
  }, [pendingPage]);

  const [approvedCustomers, setApprovedCustomers] = useState([]);
  const [approvedPage, setApprovedPage] = useState(0);
  const approvedSize = 5;
  const [approvedLoading, setApprovedLoading] = useState(false);

  const fetchApprovedCustomers = async (page = 0, size = 5) => {
    try {
      if (page === 0) {
        setApprovedLoading(true);
        const response = await request(
          axiosInstance2,
          "GET",
          "/customerApprovedInquiryListPage",
          null,
          { page, size }
        );
        setApprovedCustomers(response.data.content);
      } else {
        const response = await request(
          axiosInstance2,
          "GET",
          "/customerApprovedInquiryListPage",
          null,
          { page, size }
        );
        setApprovedCustomers((prevCustomers) => [
          ...prevCustomers,
          ...response.data.content,
        ]);
      }
    } catch (error) {
      console.error("Error fetching approved customers:", error);
    } finally {
      setApprovedLoading(false);
      const isApprovedDataEmpty = approvedCustomers.length === 0;
    }
  };

  const handleApprovedScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !approvedLoading) {
      setApprovedPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchApprovedCustomers(approvedPage);
  }, [approvedPage]);

  //get all technicians
  // const fetchTechnicians = async () => {
  //   try {
  //     const response = await request(
  //       axiosInstance1,
  //       "GET",
  //       "/activeTechnicianList"
  //     );
  //     setTechnicians(response.data);
  //   } catch (error) {
  //     console.error("Error fetching technicians:", error);
  //   }
  // };

    const openDetailsPopup = async (status, customer) => {
      setViewDetailsLoading((prev) => ({ ...prev, [customer.customerInquiryId]: true }));
    
      try {
        const response = await  request(
          axiosInstance2,
          "GET",
          `/getInquiryDetailsById/${customer.customerInquiryId}`
        );
        console.log("inquiry id: ",customer.customerInquiryId);
        setSelectedCustomer(response.data);
        console.log("inquiry data: ",selectedCustomer);
        setSelectedTechnician(response.data.technicianId);
        setCurrentStatus(status);
        setSelectedStatus(status);
        setShowDetailsPopup(true);
      } catch (error) {
        console.error("Error fetching inquiry details:", error);
      }finally{
        setViewDetailsLoading((prev) => ({ ...prev, [customer.customerInquiryId]: false }));
      }
    };

  const closeDetailsPopup = () => {
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
    setViewDetailsLoading({});
  };

  //open task popup
  const openAssignTaskPopup = (customerInquiryId, type) => {
    setSelectedCustomerInquiryId(customerInquiryId);
    setAssignTaskData({ ...assignTaskData, customerInquiryId });
    setShowAssignTaskPopup(true);
  };

  // close popup
  const closePopup = () => {
    setShowAssignTaskPopup(false);
  };

  // clear fields
  const handleClear = () => {
    setAssignTaskData({
      taskDescription: "",
      customerInquiryId: "",
    });
  };

  // field input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignTaskData({ ...assignTaskData, [name]: value });
  };

  //put task data
  const handleSendTask = async (event) => {
    event.preventDefault();

    // if (!assignTaskData.taskDescription) {
    //   setErrorMessage("Please fill in all fields.");

    //   // Display error message with SweetAlert
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Please fill in all fields.",
    //   });

    //   return;
    // }
    setIsSubmitting(true);

    try {
      setAuthHeader(getAuthToken());

      const response = await request(
        axiosInstance2,
        "PUT",
        `/approveTask/${selectedCustomerInquiryId}`,
        assignTaskData
      );
      //  console.log(response);

      setSuccessMessage("Task approved successfully.");
      setErrorMessage("");

      setAssignTaskData({});
      closePopup();

      // refresh by refetching
      fetchPendingCustomers();
      fetchApprovedCustomers();
      fetchDashboardData();
      // fetchTechnicians();

      // Show success message with SweetAlert
      MySwal.fire({
        icon: "success",
        title: "Success",
        text: "Task approved successfully.",
      });
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("An error occurred. Please try again.");
      console.log(error);

      // Show error message with SweetAlert
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const viewDetailsSpacing = (label, value) => (
    <div className="flex flex-col w-1/2 gap-1">
      <div className="text-sm text-gray-500">{label}</div>
      <div className="text-sm">{value}</div>
    </div>
  );

  return (
    <div div className="flex flex-col bg-gray-300">
      {/* Dashboard details boxes */}

      <div className="flex flex-col px-4 pt-10 lg:px-16 ">
        <div className="text-2xl font-bold text-gray-800 sm:text-3xl lg:text-4xl">
          Welcome back {welcomeUser}
        </div>
        <div className="text-sm sm:text-base">
          Check your last activity today
        </div>
      </div>

      <div className="flex flex-col items-center w-full h-auto gap-4 mt-10 lg:flex-row lg:mt-1 lg:px-16">
        {/* Mobile View - First Row */}
        <div className="flex flex-col w-full gap-4 px-4 lg:hidden">
          {/* Box 1 */}
          <div className="w-full h-[140px] bg-[#002252] rounded-xl px-10 py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-xl">
                Total Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
                {totalInquiries}
              </div>
            </div>
          </div>

          {/* Box 2 */}
          <div className="w-full h-[140px] bg-[#002252] rounded-xl px-10 py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-xl">
                Pending Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
                {pendingInquiries}
              </div>
            </div>
          </div>

          {/* Box 3 */}
          <div className="w-full h-[140px] bg-[#002252] rounded-xl px-10 py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-xl">
                Ongoing Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
                {ongoingInquiries}
              </div>
            </div>
          </div>

          {/* Box 4 */}
          <div className="w-full h-[140px] bg-[#002252] rounded-xl px-10 py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-xl">
                Completed Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
                {completeInquiries}
              </div>
            </div>
          </div>
        </div>

        {/* Large Screen View */}
        <div className="hidden w-full gap-4 px-1 lg:flex">
          {/* Box 1 */}
          <div className="w-1/2 h-[140px] bg-[#002252] rounded-xl px-10 py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                Total Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                {totalInquiries}
              </div>
            </div>
          </div>

          {/* Box 2 */}
          <div className="w-1/2 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                Pending Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                {pendingInquiries}
              </div>
            </div>
          </div>

          {/* Box 3 */}
          <div className="w-1/2 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                Ongoing Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                {ongoingInquiries}
              </div>
            </div>
          </div>

          {/* Box 4 */}
          <div className="w-1/2 h-[140px] bg-[#002252] rounded-xl py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center w-full h-full">
              <div className="text-base font-semibold text-center text-white sm:text-xl lg:text-lg">
                Completed Inquiries
              </div>
              <div className="mt-2 text-4xl font-bold text-white md:text-5xl lg:text-5xl">
                {completeInquiries}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full gap-4 lg:px-16 lg:flex-row">
        <div className="flex flex-col lg:w-1/2">
          <div className="flex flex-row justify-start w-full px-4 mt-2 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
            Approved Orders (Ongoing)
          </div>

          {/* Scrollable div */}
          <div
            className="overflow-y-auto max-h-[560px]"
            onScroll={handleApprovedScroll}
          >          
            {approvedLoading && pendingPage === 0 ? (
              <div className="flex items-center justify-center h-full">
                <CircularProgress />
              </div>
            ) : approvedCustomers.length === 0 ? (
              <div className="flex h-full items-left">
                <p className="text-lg font-semibold text-gray-500">
                  You do not have approved inquiries.
                </p>
              </div>
            ) : (
              <div className="flex flex-col pr-2">
                {approvedCustomers.map((customerData, index) => (
                  <div className="flex flex-col w-full h-full" key={index}>
                    {/* Card */}
                    <div className="bg-white lg:w-full h-[220px] overflow-x-auto mb-2 flex flex-col justify-between rounded-lg p-4">
                      <div className="flex flex-col justify-between w-full lg:flex-row">
                        <div className="flex flex-row font-bold lg:text-xl">
                          <div>{customerData.firstName}</div>
                          <div className="ml-1.5">{customerData.lastName}</div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-bold lg:text-sm">
                            Type :
                          </div>
                          <div className="pl-2 text-sm lg:font-base">
                            {customerData.type}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between w-full px-0 lg:mt-2 lg:flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Order ID :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.customerInquiryId}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Order Date :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.inquiryDate}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex flex-row justify-start text-xs font-bold lg:text-sm">
                              Preferred Date :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.serviceDate}
                              </span>
                            </div>
                            <div className="flex text-xs lg:text-sm font-base"></div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Preferred Time :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.serviceTime}
                              </span>
                            </div>
                            <div className="flex text-xs lg:text-sm font-base"></div>
                          </div>
                        </div>
                        <div>
                          <div className="flex flex-row mt-3 lg:mt-0 items-top">
                            <div className="mt-1 mr-2 text-xs font-bold lg:text-sm">
                              Status :
                            </div>
                            <div className="lg:px-3 px-2.5 py-1 text-xs lg:text-sm font-bold bg-yellow-700 rounded-full inline-block">
                              {customerData.overallStatus}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row justify-end w-full gap-4 mt-2">
                        <div className="flex flex-row w-full lg:justify-end">
                          <button
                            className="px-3 py-2 text-xs font-semibold text-black border-2 border-black rounded-full"
                            onClick={() =>
                              openDetailsPopup(
                                customerData.overallStatus,
                                customerData)}
                                disabled={viewDetailsLoading[customerData.customerInquiryId]}
                                >
                                  {viewDetailsLoading[customerData.customerInquiryId]
                                                  ? "Viewing..."
                                                  : "View Details"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:w-1/2">
          <div className="flex flex-row justify-start w-full px-4 mt-2 mb-4 text-xl font-bold text-pink-700 md:px-16 sm:text-2xl lg:text-2xl">
            Pending Orders
          </div>

          {/* Scrollable div */}
          <div
            className="overflow-y-auto max-h-[560px]"
            onScroll={handlePendingScroll}
          >
            {pendingLoading && pendingPage === 0 ? (
              <div className="flex items-center justify-center h-full">
                <CircularProgress />
              </div>
            ) : pendingCustomers.length === 0 ? (
              <div className="flex h-full items-left">
                <p className="px-4 text-lg font-semibold text-gray-500">
                  You do not have pending inquiries.
                </p>
              </div>
            ) : (
              <div className="flex flex-col pr-2">
                {pendingCustomers.map((customerData, index) => (
                  <div className="flex flex-col w-full h-full">
                    {/* Card */}

                    <div className="bg-white overflow-x-auto w-full h-[220px] mb-2 flex flex-col justify-between rounded-lg p-4">
                      <div className="flex flex-row justify-between lg:text-xl">
                        <div className="flex flex-row text-xl font-bold">
                          <div>{customerData.firstName}</div>
                          <div className="ml-1.5">{customerData.lastName}</div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-bold lg:text-sm">
                            Type :
                          </div>
                          <div className="pl-2 text-sm lg:font-base">
                            {customerData.type}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-between w-full px-0 lg:mt-2 lg:flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Order ID :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.customerInquiryId}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Order Date :{" "}
                              <span style={{ color: "gray" }}>
                                {" "}
                                {customerData.inquiryDate}
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex flex-row justify-start text-xs font-bold lg:text-sm">
                              Preferred Date :{" "}
                              <span style={{ color: "gray" }}>
                                {customerData.serviceDate}
                              </span>
                            </div>
                            <div className="flex text-xs lg:text-sm font-base"></div>
                          </div>
                          <div className="flex flex-col text-start">
                            <div className="flex justify-start text-xs font-bold lg:text-sm">
                              Preferred Time :{" "}
                              <span style={{ color: "gray" }}>
                                {" "}
                                {customerData.serviceTime}
                              </span>
                            </div>
                            <div className="flex text-xs lg:text-sm font-base"></div>
                          </div>
                        </div>
                        <div>
                          <div className="flex flex-row mt-3 lg:mt-0 items-top">
                            <div className="mt-1 mr-2 text-xs font-bold lg:text-sm">
                              Status :
                            </div>
                            <div className="lg:px-3 px-2.5 py-1 text-xs lg:text-sm font-bold bg-yellow-700 rounded-full inline-block">
                              {customerData.overallStatus}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row justify-end w-full gap-4 mt-2">
                        <div className="flex flex-row w-full lg:justify-end">
                          <button
                            className="px-3 py-1.5 text-xs font-semibold text-black border-2 border-black rounded-full"
                            onClick={() =>
                              openDetailsPopup(
                                customerData.overallStatus,   
                                customerData
                              )}
                              disabled={viewDetailsLoading[customerData.customerInquiryId]}
                              >
                                {viewDetailsLoading[customerData.customerInquiryId]
                                                ? "Viewing..."
                                                : "View Details"}
                          </button>
                          <button
                            id={customerData.customerInquiryId}
                            className="px-4 py-2 ml-4 font-semibold text-xs text-white bg-[#002252] rounded-full"
                            onClick={() =>
                              openAssignTaskPopup(
                                customerData.customerInquiryId,
                                customerData.type
                              )
                            }
                          >
                            Approve Task
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* View Details Popup */}
      {showDetailsPopup && (
        <div className="fixed inset-0 flex items-center justify-center mt-5 bg-gray-500 bg-opacity-75">
          <div className="lg:ml-70 p-3 bg-white rounded-lg w-[1250px] h-[650px] lg:h-[600px] flex flex-col mr-[40px] lg:ml-[300px] ml-[100px]">
            <div className="flex justify-end">
              <button
                className="mt-4 text-gray-500 lg:mt-0 hover:text-gray-700"
                onClick={closeDetailsPopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col w-full h-full px-2 overflow-y-auto lg:overflow-hidden">
              <div className="flex flex-col w-full h-full mx-4 lg:flex-row">
                {/* Left Column */}
                <div className="flex flex-col w-1/3 h-full gap-6">
                  {/* Technician Details */}
                  <div className="flex flex-col items-center w-full lg:items-start">
                    <div className="text-lg mb-4 font-semibold text-[#002252]">
                      Technician Details
                    </div>
                    {selectedTechnician ? (
                      <>
                        {/* Personal Details */}
                        <div className="pl-20 sm:pl-0">
                          <div className="mb-2 font-semibold text-md">
                            Personal Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "First Name",
                              selectedTechnician.firstName
                            )}
                            {viewDetailsSpacing(
                              "Last Name",
                              selectedTechnician.lastName
                            )}
                          </div>
                          <div className="flex flex-col w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "Email Address",
                              selectedTechnician.emailAddress
                            )}
                            {viewDetailsSpacing(
                              "Phone",
                              selectedTechnician.contactNumber
                            )}
                          </div>
                        </div>

                        {/* Address Details */}
                        <div className="ml-5 sm:ml-0">
                          <div className="mb-2 font-semibold text-md">
                            Address Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "Address",
                              selectedTechnician.address
                            )}
                            {viewDetailsSpacing(
                              "City",
                              selectedTechnician.city
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "State",
                              selectedTechnician.state
                            )}
                            {viewDetailsSpacing(
                              "Postal Code",
                              selectedTechnician.postalCode
                            )}
                          </div>
                        </div>

                        {/* Expertise Details */}
                        <div>
                          <div className="mb-2 font-semibold text-md">
                            Expertise Details
                          </div>
                          <div className="flex flex-row w-full gap-4 mb-4">
                            {viewDetailsSpacing(
                              "Type of Expertise",
                              selectedTechnician.typeOfExpertise
                            )}
                            {viewDetailsSpacing(
                              "License Number",
                              selectedTechnician.licenseNumber
                            )}
                            {selectedTechnician.typeOfExpertise === "Electrical" &&
                              viewDetailsSpacing("Contractor Number", selectedTechnician.contractorNumber)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="text-sm text-gray-600">
                        A technician has not been accepted yet.
                      </div>
                    )}
                  </div>
                </div>

                {/* Middle Column */}
                <div className="flex flex-col w-1/3 h-full gap-6 mt-[120px] lg:mt-0">
                  {/* Customer Details */}
                  <div className="flex flex-col w-full">
                    <div className="text-lg mb-4 font-semibold text-[#002252]">
                      Customer Details
                    </div>
                    {/* Personal Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Personal Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "First Name",
                          selectedCustomer.firstName
                        )}
                        {viewDetailsSpacing(
                          "Last Name",
                          selectedCustomer.lastName
                        )}
                      </div>
                      <div className="flex flex-col w-full gap-6 mb-4">
                        {viewDetailsSpacing(
                          "Email Address",
                          selectedCustomer.emailAddress
                        )}
                        {viewDetailsSpacing(
                          "Phone",
                          selectedCustomer.contactNumber
                        )}
                      </div>
                    </div>

                    {/* Address Details */}
                    <div>
                      <div className="mb-2 font-semibold text-md">
                        Address Details
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing(
                          "Address",
                          selectedCustomer.address
                        )}
                        {viewDetailsSpacing("City", selectedCustomer.city)}
                      </div>
                      <div className="flex flex-row w-full gap-4 mb-4">
                        {viewDetailsSpacing("State", selectedCustomer.state)}
                        {viewDetailsSpacing(
                          "Postal Code",
                          selectedCustomer.postalCode
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="flex flex-col w-1/3 h-full gap-3 mt-8">
                  <div className="text-lg font-semibold text-[#002252]">
                    Inquiry Status
                  </div>
                  <div className="w-[200px] p-2">
                    <div className="relative">
                      {statuses.map((status, index) => (
                        <React.Fragment key={index}>
                          <div className="relative flex items-center mb-3">
                            <div
                              className={`flex items-center justify-center h-4 w-4 rounded-full mr-0 ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "bg-green-500"
                                  : "bg-gray-500"
                              }`}
                            >
                              {index <= statuses.indexOf(currentStatus) && (
                                <FaCheck className="w-2 h-2 text-white" />
                              )}
                            </div>
                            <div
                              className={`text-base cursor-pointer mr-0 ${
                                index <= statuses.indexOf(currentStatus)
                                  ? "text-green-500"
                                  : "text-gray-500"
                              }`}
                              onClick={() => setSelectedStatus(status)}
                            >
                              {status}
                            </div>
                            {index < statuses.length - 1 && (
                              <div
                                className={`absolute left-1.5 top-5 h-6 w-0.5 mr-0 ${
                                  index < statuses.indexOf(currentStatus)
                                    ? "bg-green-500"
                                    : "bg-gray-500"
                                }`}
                              ></div>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="mt-6">
                      <div className="flex flex-col w-full gap-4 mb-4">
                        <div className="text-sm">
                          <strong>Type:</strong> {selectedCustomer.type}
                        </div>
                        <div className="text-sm">
                          <strong>Date:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceDate
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskDate
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeDate
                            : selectedStatus === "Paid"
                            ? selectedCustomer.acknowledgeDate
                            : selectedStatus === "Completed"
                            ? selectedCustomer.acknowledgeDate
                            : "Not Applicable"}
                        </div>
                        <div className="text-sm">
                          <strong>Time:</strong>{" "}
                          {selectedStatus === "Pending"
                            ? selectedCustomer.serviceTime
                            : selectedStatus === "Approved"
                            ? selectedCustomer.taskTime
                            : selectedStatus === "Accepted"
                            ? selectedCustomer.acknowledgeTime
                            : selectedStatus === "Paid"
                            ? selectedCustomer.acknowledgeTime
                            : selectedStatus === "Completed"
                            ? selectedCustomer.acknowledgeDate
                            : "Not Applicable"}
                        </div>
                      </div>
                      <div className="text-sm">
                        <strong>Comments:</strong>{" "}
                        {selectedStatus === "Pending"
                          ? selectedCustomer.serviceDescription
                          : selectedStatus === "Approved"
                          ? selectedCustomer.taskDescription
                          : selectedStatus === "Accepted"
                          ? selectedCustomer.acknowledgeDescription
                          : selectedStatus === "Paid"
                          ? selectedCustomer.acknowledgeDescription
                          : selectedStatus === "Completed"
                          ? selectedCustomer.acknowledgeDate
                          : "Not Applicable"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Assign Task Popup */}
      {showAssignTaskPopup && (
        <div className="fixed inset-0 flex items-center justify-center pl-12 bg-gray-500 bg-opacity-75">
          <div className="p-6 bg-white rounded-lg lg:w-[500px] h-[330px] flex flex-col">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closePopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <Typography
                variant="h6"
                sx={{ color: "#002252", fontWeight: "bold" }}
              >
                Approve Task
              </Typography>
              <form onSubmit={handleSendTask}>
                <Grid container>
                  <Grid item xs={12} sm={12} sx={{ mt: 3 }}>
                    <TextField
                      id="taskDescription"
                      name="taskDescription"
                      label="Task Remarks"
                      multiline
                      rows={4}
                      value={assignTaskData.taskDescription}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" sx={{ marginTop: 4 }}>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 5,
                      background: " #002252",
                      marginRight: 4,
                      fontWeight: "bold",
                    }}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: 5,
                      background: "#002252",
                      fontWeight: "bold",
                    }}
                    onClick={handleSendTask}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Approve Task"}
                  </Button>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col w-full gap-4 p-4 mt-8 md:flex-row md:pl-16 md:mt-16">
        <div className="flex flex-col w-full gap-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 md:pr-16">
            <div className="flex flex-col w-full h-[120px] bg-[#b5ab40] rounded-xl items-center justify-center">
              <div className="flex flex-col items-center">
                <div className="text-lg font-semibold text-center text-white">
                  Active Customers
                </div>
                <div className="mt-2 text-4xl font-bold text-white">
                  {activeCustomers}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full h-[120px] bg-[#002252] rounded-xl items-center justify-center">
              <div className="flex flex-col items-center">
                <div className="text-lg font-semibold text-center text-white">
                  Inactive Customers
                </div>
                <div className="mt-2 text-4xl font-bold text-white">
                  {inactiveCustomers}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full h-[120px] bg-[#b5ab40] rounded-xl items-center justify-center">
              <div className="flex flex-col items-center">
                <div className="text-lg font-semibold text-center text-white">
                  Active Technician
                </div>
                <div className="mt-2 text-4xl font-bold text-white">
                  {activeTechnicians}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full h-[120px] bg-[#002252] rounded-xl items-center justify-center">
              <div className="flex flex-col items-center">
                <div className="text-lg font-semibold text-center text-white">
                  Inactive Technician
                </div>
                <div className="mt-2 text-4xl font-bold text-white">
                  {inactiveTechnicians}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;