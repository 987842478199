import React, { useEffect, useState } from "react";
import CompletedInquiryCard from "../../components/dashboard/InquiryCard";
import CustomerViewDetailsPopup from "../../components/dashboard/CustomerViewDetails";
import {
  request,
  axiosInstance1,
  axiosInstance2,
} from "../../config/axiosConfig";
import { CircularProgress, Typography } from "@mui/material";

const CustomerCompletedInquiries = () => {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [viewDetailsLoading, setViewDetailsLoading] = useState({});
  const [isCustomerActive, setIsCustomerActive] = useState(null);
  const [dots, setDots] = useState("");

  const userId = localStorage.getItem("userid");

  useEffect(() => {
    fetchCustomers();
    fetchCustomerData();

    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500);

    Promise.all([fetchCustomerData(), fetchCustomers()]).finally(() => {
      clearInterval(interval);
      setLoading(false);
    });

    return () => clearInterval(interval);
  }, []);

  const fetchCustomerData = async () => {
    try {
      const response = await request(
        axiosInstance1,
        "GET",
        `/getCustomerDetailsByUserId/${userId}`
      );
      if (response.data.status === "Active") {
        setIsCustomerActive(true);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await request(
        axiosInstance2,
        "GET",
        "/getCustomerCompletedInquiries",
        null,
        { userid: userId }
      );
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDetailsPopup = async (overallStatus, inquiryData) => {
    setViewDetailsLoading((prev) => ({
      ...prev,
      [inquiryData.customerInquiryId]: true,
    }));

    try {
      const response = await request(
        axiosInstance2,
        "GET",
        `/getInquiryDetailsById/${inquiryData.customerInquiryId}`
      );
      console.log("inquiry id: ", inquiryData.customerInquiryId);
      setSelectedCustomer(response.data);
      console.log("inquiry data: ", selectedCustomer);
      setSelectedTechnician(response.data.technicianId);
      setCurrentStatus(overallStatus);
      setSelectedStatus(overallStatus);
      setShowDetailsPopup(true);
    } catch (error) {
      console.error("Error fetching inquiry details:", error);
    } finally {
      setViewDetailsLoading((prev) => ({
        ...prev,
        [inquiryData.customerInquiryId]: false,
      }));
    }
  };

  const closeDetailsPopup = () => {
    setSelectedTechnician(null);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
    setViewDetailsLoading({});
  };

  return (
    <div className="flex flex-column justify-start w-full px-4 mt-4 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
      Completed Inquiries
      {isCustomerActive === null ? (
        <div className="text-gray-500 font-bold text-xl text-center py-5">
          Loading{dots}
        </div>
      ) : isCustomerActive ? (
        customers.length === 0 ? (
          <div className="flex items-left h-full mt-2">
            <p className="text-gray-500 text-lg font-semibold">
              You do not have completed inquiries.
            </p>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-[500px] mb-4 mt-8">
            {customers.map((customerData, index) => (
              <CompletedInquiryCard
                key={index}
                inquiryData={customerData}
                openDetailsPopup={openDetailsPopup}
                viewDetailsLoading={viewDetailsLoading}
              />
            ))}
          </div>
        )
      ) : (
        <Typography
          variant="h6"
          color="error"
          align="center"
          sx={{ marginTop: 2 }}
        >
          Your account is currently inactive. Please wait for admin approval.
        </Typography>
      )}
      <br></br>
      {showDetailsPopup && (
        <CustomerViewDetailsPopup
          closeDetailsPopup={closeDetailsPopup}
          selectedTechnician={selectedTechnician}
          overallStatus={currentStatus}
          selectedCustomer={selectedCustomer}
        />
      )}
    </div>
  );
};

export default CustomerCompletedInquiries;
